import type { api } from '@meterup/proto';
import {
  Badge,
  CopyCapsule,
  List,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  MinList,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  SmallMono2,
} from '@meterup/metric';
import { get } from 'lodash';
import { useQuery } from 'react-query';

import type { FixMeLater } from '../../../../utils/FixMeLater';
import { fetchControllerConfig } from '../../../../api/controllers_api';

export default function VPNServerDetailContent({
  controllerName,
  vpnServer,
}: {
  controllerName: string;
  vpnServer: api.VPNServer;
}) {
  const config = useQuery(
    ['controller', controllerName, 'config'],
    async () => fetchControllerConfig(controllerName),
    {
      suspense: true,
      refetchInterval: 1000,
    },
  ).data?.config as FixMeLater;

  const enabled = !!get(config, ['meter.v1.network.vpn.client', 'enabled']);
  const address = get(config, ['meter.v1.network.vpn.client', 'address']);

  return (
    <>
      <List>
        <ListItemPair>
          <ListItemLabel>Status</ListItemLabel>
          <ListItemValue>
            {enabled !== null && enabled !== undefined && enabled && (
              <Badge variant="positive" ends="pill">
                Enabled
              </Badge>
            )}
            {enabled === undefined ||
              enabled === null ||
              (!enabled && (
                <Badge variant="neutral" ends="pill">
                  Disabled
                </Badge>
              ))}
          </ListItemValue>
        </ListItemPair>
      </List>

      <MinList>
        <MinListItemHeader icon="controller">
          <MinListTitle>Details</MinListTitle>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Endpoint</MinListItemLabel>
          <MinListItemValue>
            <SmallMono2 style={{ wordBreak: 'break-all', textAlign: 'right' }}>
              <CopyCapsule
                textValue={vpnServer.endpoint}
                aria-label="Copy VPN server endpoint"
                arrangement="leading-icon"
              >
                {vpnServer.endpoint}
              </CopyCapsule>
            </SmallMono2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Public key</MinListItemLabel>
          <MinListItemValue>
            <SmallMono2 style={{ wordBreak: 'break-all', textAlign: 'right' }}>
              <CopyCapsule
                textValue={vpnServer.public_key?.toString() ?? ''}
                aria-label="Copy VPN server public key"
                arrangement="leading-icon"
              >
                {vpnServer.public_key}
              </CopyCapsule>
            </SmallMono2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Port</MinListItemLabel>
          <MinListItemValue>
            <SmallMono2 style={{ wordBreak: 'break-all' }}>
              <CopyCapsule
                textValue={vpnServer.port?.toString() ?? ''}
                aria-label="Copy VPN server port"
                arrangement="leading-icon"
              >
                {vpnServer.port}
              </CopyCapsule>
            </SmallMono2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Address</MinListItemLabel>
          <MinListItemValue>
            <SmallMono2 style={{ wordBreak: 'break-all' }}>
              <CopyCapsule
                textValue={address ?? ''}
                aria-label="Copy VPN server address"
                arrangement="leading-icon"
              >
                {address}
              </CopyCapsule>
            </SmallMono2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>
    </>
  );
}
