import type { VPNClient } from '@meterup/proto/esm/api';
import type { CellProps, Column } from 'react-table';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { Button, HStack, space } from '@meterup/metric';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import type { VPNClientWithUser } from '../../api/types';
import { fetchControllerJSON, fetchVPNClientsWithUsers } from '../../api/controllers_api';
import { paths } from '../../constants';
import { useCurrentTimezone } from '../../providers/CurrentTimezoneProvider';
import { makeDrawerLink } from '../../utils/makeLink';
import { AutoTable } from '../AutoTable/AutoTable';
import { Nav } from '../Nav';
import { TimestampWithTimezone, TimezoneAbbreviation } from '../timestamps';

interface VPNClientsWidgetProps {
  controllerName: string;
}

const VPNClientsWidget = ({ controllerName }: VPNClientsWidgetProps) => {
  const timezone = useCurrentTimezone();
  const tz = TimezoneAbbreviation(timezone);
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.VPNClientDetailPage);

  const controller = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  const vpnClientsWithUser =
    useQuery(
      ['controller', controllerName, 'vpn-clients'],
      () => fetchVPNClientsWithUsers(controllerName),
      {
        suspense: true,
      },
    ).data ?? [];

  expectDefinedOrThrow(controller, new ResourceNotFoundError('Controller response not found'));
  expectDefinedOrThrow(
    vpnClientsWithUser,
    new ResourceNotFoundError('VPN clients with user response not found'),
  );

  const columns: Column<VPNClientWithUser>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (e) => e.vpnClient.name,
      },
      {
        Header: 'IP Address',
        accessor: (e) => e.vpnClient.ip_address,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (e: CellProps<VPNClient, string>) => (
          <div style={{ whiteSpace: 'normal' }}>{e.value}</div>
        ),
      },
      {
        Header: `Created At (${tz})`,
        accessor: (e) => e.vpnClient.created_at,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (e: CellProps<VPNClient, string>) => (
          <div>
            <TimestampWithTimezone value={e.value} timezone={timezone} />
          </div>
        ),
      },
      {
        Header: `User`,
        accessor: (e) => e.user?.email,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (e: CellProps<VPNClient, string>) => (
          <div style={{ whiteSpace: 'normal' }}>{e.value}</div>
        ),
      },
    ],
    [timezone, tz],
  );

  return (
    <AutoTable
      isRowSelected={(row) => row.vpnClient.sid === drawerParams?.clientSid}
      linkProps={(row) => ({
        to: makeDrawerLink(paths.drawers.VPNClientDetailPage, {
          controllerName,
          clientSid: row.vpnClient.sid,
        }),
      })}
      tabs={
        <HStack spacing={space(8)}>
          <Button
            as={Link}
            to={makeDrawerLink(paths.drawers.VPNServerDetailPage, {
              controllerName,
            })}
            variant="tertiary"
            arrangement="leading-icon"
          >
            Server details
          </Button>
        </HStack>
      }
      columns={columns}
      data={vpnClientsWithUser}
      additionalControls={
        <Button
          as={Link}
          to={makeDrawerLink(paths.drawers.VPNClientCreate, {
            controllerName,
          })}
          variant="tertiary"
          icon="plusCircle"
          arrangement="leading-icon"
        >
          Add client
        </Button>
      }
    />
  );
};

export default VPNClientsWidget;
