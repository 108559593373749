import type { api } from '@meterup/proto';
import { observer, useCommand, useRegisterCommands } from '@meterup/command';
import { Header, HeaderLogo } from '@meterup/metric';
import React from 'react';
import { useFocusVisible } from 'react-aria';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { fetchCompaniesJSON } from '../api/company_api';
import { HeaderDropdownMenu } from '../components/HeaderDropdownMenu';
import { paths } from '../constants';
import { focusVisibleClassName, styled } from '../stitches';
import { makeDrawerLink } from '../utils/makeLink';

const Container = styled('div', {
  height: '100%',
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content minmax(0, 1fr)',
  gridTemplateAreas: '"header" "content"',
});

const HeaderContainer = styled('div', {
  gridArea: 'header',
  display: 'block',
  zIndex: 3,
  '@maxSm': {
    display: 'none',
  },
});

const ContentContainer = styled('div', {
  gridArea: 'content',
  overflow: 'auto',
  zIndex: 1,
});

const empty: api.CompanyResponse[] = [];

export const AppLayout = observer(({ children }: React.PropsWithChildren<{}>) => {
  const { isFocusVisible } = useFocusVisible();

  const companies =
    useQuery(['companies', 'filter', 'all'], () => fetchCompaniesJSON()).data ?? empty;

  const { state } = useCommand();

  const navigate = useNavigate();

  useRegisterCommands(
    companies
      ? [
          state.nodeFactory.directory({
            id: 'global-companies-search',
            display: 'Go to company…',
            label: 'Go to company…',
            shortcut: '$mod+/',
            children: companies.map((company) =>
              state.nodeFactory.action({
                id: company.sid,
                display: `${company.name} (${company.slug})`,
                label: `${company.name} (${company.slug})`,
                synonyms: company.slug,
                icon: 'company',
                onSelect() {
                  navigate(
                    makeDrawerLink(paths.drawers.CompanySummary, { companyName: company.slug }),
                  );
                },
              }),
            ),
          }),
        ]
      : [],
    [companies],
  );

  return (
    <Container className={isFocusVisible ? focusVisibleClassName : ''}>
      <HeaderContainer>
        <Header logo={<HeaderLogo href="/" />} controls={<HeaderDropdownMenu />} />
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
});
