import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { fetchVPNServer } from '../../../../api/vpn';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { makeDrawerLink } from '../../../../utils/makeLink';
import VPNServerDetailContent from './VPNServerDetailContent';

export const Meta = () => ({
  path: '/controllers/:controllerName/vpn-servers',
});

export default function VPNServerDetailPage() {
  const closeDrawer = useCloseDrawerCallback();
  const navigate = useNavigate();
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VPNServerDetailPage),
  );

  const vpnServerDetails = useQuery(
    ['controller', controllerName, 'vpn-servers'],
    () => fetchVPNServer(controllerName),
    { suspense: true, enabled: true },
  ).data;

  expectDefinedOrThrow(
    vpnServerDetails,
    new ResourceNotFoundError('VPN server response not found'),
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>VPN server</DrawerTitle>
        <DrawerControls>
          <DropdownMenu>
            <DropdownMenuTriggerButton
              arrangement="hidden-label"
              icon="overflowVertical"
              variant="tertiary"
            >
              Actions
            </DropdownMenuTriggerButton>
            <DropdownMenuContent>
              <DropdownMenuItem
                icon="pencil"
                onSelect={() =>
                  navigate(
                    makeDrawerLink(paths.drawers.VPNServerEdit, {
                      controllerName,
                    }),
                  )
                }
              >
                Edit VPN server
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <CloseDrawerButton onClick={closeDrawer} />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <VPNServerDetailContent controllerName={controllerName} vpnServer={vpnServerDetails} />
      </DrawerContent>
    </Drawer>
  );
}
