import { getErrorMessage, isDefined } from '@meterup/common';
import { Alert, Body2, Button, space, TextInput, VStack } from '@meterup/metric';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { createVPNServer } from '../../../../api/vpn';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogCloseButton,
  DialogContent,
  DialogControls,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  DialogTrigger,
} from '../../../../components/Dialog/Dialog';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';

export function VPNServerCreateDialog({
  isOpen,
  onOpenChange,
  controllerName,
}: {
  controllerName: string;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const [publicKeyString, setPublicKeyString] = useState<string>('');

  const createVPNServerMutation = useMutation(
    async (publicKey: string) => {
      await createVPNServer(controllerName, publicKey);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        onOpenChange(false);
        closeDrawer();
      },
    },
  );

  const actionCreate = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    createVPNServerMutation.mutate(publicKeyString);
  };

  const errorMessage = isDefined(createVPNServerMutation.error)
    ? getErrorMessage(createVPNServerMutation.error)
    : null;

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      createVPNServerMutation.reset();
    }
    onOpenChange(value);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger />
      <DialogContent>
        <form onSubmit={actionCreate}>
          <DialogHeader>
            <DialogTitle>Create VPN Server</DialogTitle>
            <DialogControls>
              <DialogCloseButton />
            </DialogControls>
          </DialogHeader>
          <DialogBody>
            {isDefined(errorMessage) && (
              <Alert
                icon="warning"
                variant="negative"
                heading="Failed to create VPN server"
                copy={errorMessage}
                cornerStyle="square"
              />
            )}
            <DialogSection>
              <VStack align="stretch" spacing={space(20)}>
                <VStack align="stretch" spacing={space(8)}>
                  <Body2 as="p">Please enter the WireGuard public key for this controller</Body2>
                </VStack>
                <TextInput
                  controlSize="large"
                  aria-label="Enter WireGuard public key"
                  onChange={(value) => setPublicKeyString(value)}
                />
              </VStack>
            </DialogSection>
          </DialogBody>
          <DialogFooter>
            <DialogControls>
              <DialogClose asChild>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
              <Button type="submit" variant="primary">
                Create
              </Button>
            </DialogControls>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
