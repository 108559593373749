import type { ControllerConfigObject } from '@meterup/config';
import type { Dispatch, SetStateAction } from 'react';
import { expectDefinedOrThrow } from '@meterup/common';
import { doesDraftHaveChanges, MeterControllerConfig, serializeConfigJSON } from '@meterup/config';
import { isEqual } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  deleteControllerConfigKey,
  fetchControllerConfig,
  upsertControllerConfigKey,
} from '../api/controllers_api';
import { useMemoCompare } from '../hooks/useMemoCompare';
import { logError } from '../utils/logError';

interface ConfigEditorValue {
  originalText: string;
  draftText: string;
  draftModel: MeterControllerConfig;
  setDraftModel: Dispatch<SetStateAction<MeterControllerConfig>>;
  setDraftText: Dispatch<SetStateAction<string>>;
  saveDraftModel: () => void;
  isSaving: boolean;
  saveDraftModelError: unknown | null;
  resetDraft: () => void;
  resetDraftTextToModel: () => void;
  isDraftModified: boolean;
  isDraftTextValidJSON: boolean;
}

function saveConfigDiff(
  controllerName: string,
  modified: Record<string, any>,
  original: Record<string, any>,
) {
  const deletedKeys = Object.keys(original)
    .filter((key) => !key.startsWith('meter.v1.auth'))
    .filter((key) => !(key in modified));

  const modifiedKeys = Object.keys(modified)
    .filter((key) => !key.startsWith('meter.v1.auth'))
    .filter((key) => !(key in original) || !isEqual(original[key], modified[key]));

  const modifiedPromises = modifiedKeys.map((key) =>
    upsertControllerConfigKey(controllerName, key, modified[key]),
  );

  const deletedPromises = deletedKeys.map((key) => deleteControllerConfigKey(controllerName, key));

  return Promise.all([...modifiedPromises, ...deletedPromises]);
}

const ConfigEditorContext = createContext<ConfigEditorValue>({} as any);

function parseModelFromText(newValue: string) {
  let parsedValue: Record<string, unknown>;

  try {
    parsedValue = JSON.parse(newValue);
  } catch (e) {
    return null;
  }

  let modelValue: MeterControllerConfig;
  try {
    modelValue = MeterControllerConfig.fromJSON(parsedValue as ControllerConfigObject);
  } catch (e) {
    logError(e);
    return null;
  }

  return modelValue;
}

export const ConfigEditorProvider = ({
  children,
  controllerName,
}: {
  children: React.ReactNode;
  controllerName: string;
}) => {
  const liveOriginal = useQuery(
    ['controllers', controllerName, 'config'],
    () => fetchControllerConfig(controllerName),
    { suspense: true },
  ).data?.config as Record<string, unknown>;

  expectDefinedOrThrow(liveOriginal);

  const memoizedOriginal = useMemoCompare(liveOriginal);

  const originalText = useMemo(
    () => serializeConfigJSON(memoizedOriginal, { space: 2 }),
    [memoizedOriginal],
  );
  const originalModel = useMemo(
    () => MeterControllerConfig.fromJSON(memoizedOriginal as ControllerConfigObject),
    [memoizedOriginal],
  );

  const [draftText, setDraftText] = useState(originalText);
  const [draftModel, setDraftModel] = useState(() =>
    MeterControllerConfig.fromJSON(memoizedOriginal as ControllerConfigObject),
  );

  const queryClient = useQueryClient();

  const [isDraftModified, setIsDraftModified] = useState(false);
  const [isDraftTextValidJSON, setIsDraftTextValidJSON] = useState(true);

  const saveChangesMutation = useMutation(
    async () => {
      const modified = draftModel.toJSON() as Record<string, any>;
      return saveConfigDiff(controllerName, modified, memoizedOriginal);
    },
    {
      onSuccess: async () => {
        setIsDraftModified(false);
        await queryClient.invalidateQueries(['controllers', controllerName]);
      },
    },
  );

  const updateDraftText = useCallback(
    (value: SetStateAction<string>) => {
      const valueFn = value instanceof Function ? value : () => value;

      setDraftText((prev) => {
        const nextText = valueFn(prev);

        const modelValue = parseModelFromText(nextText);

        if (modelValue) {
          const nextDraft = modelValue.toJSON() as Record<string, any>;
          setDraftModel(modelValue);
          // Using `compare` rather than `isEqual` to avoid some false positives
          // from `isEqual`
          setIsDraftModified(doesDraftHaveChanges(memoizedOriginal, nextDraft));
          setIsDraftTextValidJSON(true);
        } else {
          setIsDraftModified(true);
          setIsDraftTextValidJSON(false);
        }

        return nextText;
      });
    },
    [memoizedOriginal],
  );

  const updateDraftModel = useCallback(
    (value: SetStateAction<MeterControllerConfig>) => {
      const valueFn = value instanceof Function ? value : () => value;

      setDraftModel((prev) => {
        const nextValue = valueFn(prev);

        const nextDraft = nextValue.toJSON() as Record<string, any>;
        const nextText = serializeConfigJSON(nextDraft, { space: 2 });
        setDraftText(nextText);
        setIsDraftModified(doesDraftHaveChanges(memoizedOriginal, nextDraft));

        return nextValue;
      });
    },
    [memoizedOriginal],
  );

  useEffect(() => {
    if (!isDraftModified) {
      updateDraftModel(originalModel);
    }
    // NOTE: Deliberately ignoring isDraftModified to avoid updating the model mid-save
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalModel, updateDraftModel]);

  const value = useMemo(
    (): ConfigEditorValue => ({
      originalText,
      draftModel,
      draftText,
      setDraftModel: updateDraftModel,
      setDraftText: updateDraftText,
      saveDraftModel: () => saveChangesMutation.mutate(),
      resetDraft: () => updateDraftModel(originalModel),
      resetDraftTextToModel: () => updateDraftText(serializeConfigJSON(draftModel, { space: 2 })),
      isDraftModified,
      isDraftTextValidJSON,
      isSaving: saveChangesMutation.isLoading,
      saveDraftModelError: saveChangesMutation.error,
    }),
    [
      originalText,
      draftModel,
      draftText,
      updateDraftModel,
      updateDraftText,
      isDraftModified,
      isDraftTextValidJSON,
      saveChangesMutation,
      originalModel,
    ],
  );

  return <ConfigEditorContext.Provider value={value}>{children}</ConfigEditorContext.Provider>;
};

export const useConfigEditor = () => {
  const value = useContext(ConfigEditorContext);

  expectDefinedOrThrow(
    value,
    new Error('useConfigEditor must be used within a ConfigEditorProvider'),
  );

  return value;
};
