/* eslint-disable react/no-unstable-nested-components */
import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  NeutralBadge,
  PositiveBadge,
  ResourceNotFoundError,
  ValueOrUnknownBadge,
} from '@meterup/common';
import { HStack, space, SwitchIcon } from '@meterup/metric';
import oui from 'oui';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchClientsJSON } from '../../../api/clients_api';
import { fetchControllerJSON } from '../../../api/controllers_api';
import { fetchDevices } from '../../../api/devices_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/switches',
});

export default function ControllerSwitchesList() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerSwitchesList),
  );

  const network = useQuery(
    ['controller', controllerName, 'json'],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(network, new ResourceNotFoundError('Controller not found'));

  const devices = useQuery(
    ['controller', controllerName, 'switches'],
    () => fetchDevices(controllerName),
    {
      suspense: true,
    },
  ).data;
  const switches = devices?.switches ?? [];

  const connectedClients =
    useQuery(['clients', controllerName], () => fetchClientsJSON(controllerName)).data ?? [];
  const macAddrs = new Map<string, boolean>();
  connectedClients.forEach((client) => {
    macAddrs.set(client.mac, true);
  });

  const switchColumns: Column<api.SwitchResponse>[] = [
    {
      Header: 'Location',
      accessor: (row) => row.location,
      Cell: (props: CellProps<api.SwitchResponse, string>) => (
        <HStack spacing={space(8)}>
          <SwitchIcon size="small" />
          {props.value}
        </HStack>
      ),
    },
    {
      Header: 'MAC address',
      accessor: (row) => row.mac,
    },
    {
      Header: 'Manufacturer',
      accessor: (row) => oui(row.mac),
    },
    {
      Header: 'Model',
      accessor: (row) => row.model,
    },
    {
      Header: 'Serial number',
      accessor: (row) => row.serial_number,
    },
    {
      Header: 'Inventory status',
      accessor: (row) => row.inventory_status,
      Cell: ValueOrUnknownBadge,
    },
    {
      Header: 'Status',
      accessor: (row) => row.mac,
      Cell: (props: CellProps<api.SwitchResponse, string>) => (
        <HStack spacing={space(8)}>
          {macAddrs.get(props.value) ? (
            <PositiveBadge>Connected</PositiveBadge>
          ) : (
            <NeutralBadge>Not connected</NeutralBadge>
          )}
        </HStack>
      ),
    },
  ];

  useDocumentTitle('Switches', controllerName, network);

  return <AutoTable columns={switchColumns} data={switches} />;
}
