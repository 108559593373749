import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, isDefined, isDefinedAndNotEmpty } from '@meterup/common';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  ListItemHeader,
  ListTitle,
  SecondaryField,
  TextInput,
  ToggleInput,
} from '@meterup/metric';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { FixMeLater } from '../../../../utils/FixMeLater';
import type { ValidVPNServerEditData } from './form_data';
import { fetchControllerConfig, upsertControllerConfigKey } from '../../../../api/controllers_api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { validVPNServerEditData } from './form_data';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/vpn-server/edit',
});

const StyledForm = styled('form', {
  display: 'contents',
});

export default function VPNServerEdit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VPNServerEdit),
  );
  const toServerDetail = makeDrawerLink(paths.drawers.VPNServerDetailPage, { controllerName });

  const config = useQuery(
    ['controller', controllerName, 'config'],
    async () => fetchControllerConfig(controllerName),
    {
      suspense: true,
      refetchInterval: 1000,
    },
  ).data?.config as FixMeLater;

  const enabled = !!get(config, ['meter.v1.network.vpn.client', 'enabled']);
  const port = get(config, ['meter.v1.network.vpn.client', 'port']).toString();
  const address = get(config, ['meter.v1.network.vpn.client', 'address']);

  const updateConfig = function (data: ValidVPNServerEditData) {
    config['meter.v1.network.vpn.client'].enabled = !!data.enabled;
    if (isDefinedAndNotEmpty(data.port)) {
      config['meter.v1.network.vpn.client'].port = Number(data.port);
    }
    if (isDefinedAndNotEmpty(data.address)) {
      config['meter.v1.network.vpn.client'].address = data.address;
    }
    upsertControllerConfigKey(
      controllerName,
      'meter.v1.network.vpn.client',
      config['meter.v1.network.vpn.client'],
    );
  };

  const editDrawerMutation = useMutation(
    async (data: ValidVPNServerEditData) => {
      await updateConfig(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controllers', controllerName, 'vpn-server']);
        navigate(toServerDetail);
      },
    },
  );

  return (
    <Formik<ValidVPNServerEditData>
      initialValues={{ port: port ?? '', address: address ?? '', enabled }}
      validationSchema={toFormikValidationSchema(validVPNServerEditData)}
      onSubmit={(values) => editDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Edit VPN server</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>

            <DrawerContent>
              <Alert
                icon="attention"
                variant="brand"
                heading="Downtime expected"
                copy="Editing VPN server configuration details results in a network restart. Please expect up to 30 seconds of network downtime."
                cornerStyle="round"
              />
              {isDefined(editDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={JSON.stringify(editDrawerMutation.error)}
                />
              )}

              <FieldContainer>
                <ListItemHeader>
                  <ListTitle>VPN server details</ListTitle>
                </ListItemHeader>
                <FieldProvider name="port">
                  <SecondaryField label="Port" element={<TextInput />} />
                </FieldProvider>
                <FieldProvider name="address">
                  <SecondaryField label="Address" element={<TextInput />} />
                </FieldProvider>
                <FieldProvider name="enabled">
                  <SecondaryField
                    label="Enabled"
                    element={<ToggleInput selected={!!form.values.enabled} />}
                  />
                </FieldProvider>
              </FieldContainer>
            </DrawerContent>

            <DrawerFooter>
              <DrawerControls>
                <Button
                  type="button"
                  variant="secondary"
                  as={Link}
                  to={toServerDetail}
                  loading={editDrawerMutation.isLoading}
                >
                  Cancel
                </Button>
                <Button type="submit" loading={editDrawerMutation.isLoading}>
                  Save
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
