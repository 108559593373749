import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useCommand, useRegisterCommands } from '@meterup/command';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { formatLifecycleStatus } from '@meterup/common/src/helpers/lifecycleStatus';
import {
  Alert,
  Body2,
  Button,
  CopyCapsule,
  Link,
  List,
  ListControls,
  ListItem,
  ListItemHeader,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  ListTitle,
} from '@meterup/metric';
import { copyTextToClipboard } from '@meterup/metric/src/utilities/clipboard';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { fetchControllerJSON, fetchIncidents } from '../../../api/controllers_api';
import { AccessPointsWidget } from '../../../components/AccessPointsWidget';
import { ClientsWidget } from '../../../components/ClientsWidget';
import Passwords from '../../../components/LegacyWirelessNetworksWidget';
import { Nav } from '../../../components/Nav';
import { Page, PageSection } from '../../../components/Page';
import { ServiceSetsWidgetCOS } from '../../../components/ServiceSetsWidgetCOS';
import { CurrentLocalTime, StringToDateTime } from '../../../components/timestamps';
import { WidgetSuspenseAndErrorBoundary } from '../../../components/WidgetSuspenseAndErrorBoundary';
import { paths } from '../../../constants';
import { useControllerVersion } from '../../../hooks/useControllerVersion';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { styled } from '../../../stitches';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName',
});

const PageColumns = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$16',
  '@sm': {
    gridTemplateColumns: '2fr 3fr',
  },
});

const PageColumn = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
});

const PageFirstColumn = styled(PageColumn, {
  '@sm': {
    gridColumn: '1',
  },
  vStack: '$16',
  alignItems: 'stretch',
});

const PageSecondColumn = styled(PageColumn, {
  '@sm': {
    gridColumn: '2',
  },
  vStack: '$16',
  alignItems: 'stretch',
});

const ControllerMetadataWidget = ({ controllerName }: { controllerName: string }) => {
  const { data: networkInfoJSON } = useQuery(
    ['controller', controllerName, 'network-info'],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  );

  expectDefinedOrThrow(
    networkInfoJSON,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const controllerVersion = useControllerVersion(controllerName);

  const navigate = useNavigate();
  const { state } = useCommand();

  const metadataEditLink = makeDrawerLink(paths.drawers.EditControllerMetadata, { controllerName });

  useRegisterCommands([
    state.nodeFactory.action({
      id: 'edit-metadata',
      label: 'Edit metadata',
      display: 'Edit metadata',
      icon: 'pencil',
      synonyms: 'change name address update',
      onSelect() {
        navigate(metadataEditLink);
      },
    }),
    state.nodeFactory.action({
      id: 'copy-name',
      label: 'Copy name to clipboard',
      display: 'Copy name to clipboard',
      icon: 'copy',
      shortcut: 'Control+Shift+C',
      onSelect() {
        copyTextToClipboard(controllerName);
      },
    }),
    state.nodeFactory.action({
      id: 'copy-company-slug',
      label: 'Copy company slug to clipboard',
      display: 'Copy company slug to clipboard',
      icon: 'copy',
      shortcut: 'Control+Shift+S',
      onSelect() {
        copyTextToClipboard(networkInfoJSON.company_slug);
      },
    }),
    state.nodeFactory.action({
      id: 'copy-controller-address',
      label: 'Copy controller address to clipboard',
      display: 'Copy controller address to clipboard',
      icon: 'copy',
      shortcut: 'Control+Shift+A',
      onSelect() {
        copyTextToClipboard(networkInfoJSON.address);
      },
    }),
  ]);

  return (
    <List>
      <ListItemHeader>
        <ListTitle>Metadata</ListTitle>
        <ListControls>
          <Button as={RouterLink} to={metadataEditLink} variant="secondary" size="small">
            Edit
          </Button>
        </ListControls>
      </ListItemHeader>
      <ListItemPair>
        <ListItemLabel>Customer</ListItemLabel>
        <ListItemValue>
          {isDefinedAndNotEmpty(networkInfoJSON.company_slug) ? (
            <Link
              as={RouterLink}
              to={Nav.makeTo({
                root: makeLink(paths.pages.CompaniesList, {}),
                drawer: makeLink(paths.drawers.CompanySummary, {
                  companyName: networkInfoJSON.company_slug,
                }),
              })}
            >
              {networkInfoJSON.company_slug}
            </Link>
          ) : (
            'N / A'
          )}
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Network</ListItemLabel>
        <ListItemValue>
          <CopyCapsule
            aria-label="Copy network name"
            arrangement="leading-icon"
            textValue={controllerName}
          >
            {controllerName}
          </CopyCapsule>
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Lifecycle status</ListItemLabel>
        <ListItemValue>{formatLifecycleStatus(networkInfoJSON.lifecycle_status)} </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Address</ListItemLabel>
        <ListItemValue>{networkInfoJSON.address} </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Square footage</ListItemLabel>
        <ListItemValue>{`${networkInfoJSON.square_feet || 'n/a'} sq ft`} </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Generation</ListItemLabel>
        <ListItemValue>{controllerVersion.versionString}</ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Patch panel diagram</ListItemLabel>
        <ListItemValue>
          {networkInfoJSON?.noc_metadata?.patch_panel_diagram_url ? (
            <Button
              as="a"
              href={networkInfoJSON.noc_metadata.patch_panel_diagram_url}
              target="_blank"
              size="small"
              variant="secondary"
            >
              Open diagram
            </Button>
          ) : (
            'n/a'
          )}
        </ListItemValue>
      </ListItemPair>

      {networkInfoJSON?.noc_metadata?.notes && (
        <ListItem style={{ flexDirection: 'column', alignItems: 'stretch' }}>
          <ListItemLabel style={{ alignSelf: 'flex-start' }}>Notes</ListItemLabel>
          <Body2 style={{ whiteSpace: 'pre-line' }}>{networkInfoJSON.noc_metadata.notes}</Body2>
        </ListItem>
      )}
    </List>
  );
};

export default function ControllerDetails() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  expectDefinedOrThrow(controllerName);

  const controllerVersion = useControllerVersion(controllerName);

  const incidents =
    useQuery(['controller', controllerName, 'incidents'], () => fetchIncidents(controllerName), {
      suspense: true,
    }).data ?? [];

  const now = CurrentLocalTime();
  const currentIncident = incidents.find((i) => {
    const start = StringToDateTime(i.start_time ?? '');
    const end = StringToDateTime(i.end_time ?? '');
    return start <= now && now <= end;
  });

  const AlertContainer = styled('div', { marginBottom: '$16' });

  const currentIncidentAlert = currentIncident ? (
    <AlertContainer>
      <Alert
        icon="attention"
        variant="negative"
        heading="Ongoing incident"
        copy="There is an active incident on this controller."
        cornerStyle="square"
        trailingButtons={
          <Button
            as={RouterLink}
            to={Nav.makeTo({
              root: makeLink(paths.pages.ControllerIncidentsList, { controllerName }),
              drawer: makeLink(paths.drawers.IncidentDetail, {
                controllerName,
                id: currentIncident.sid,
              }),
            })}
          >
            View incident
          </Button>
        }
      />
    </AlertContainer>
  ) : null;

  useDocumentTitle('', controllerName);

  return (
    <Page style={{ padding: 0 }}>
      <PageSection style={{ padding: 16 }}>
        {currentIncidentAlert}
        <PageColumns>
          <PageFirstColumn>
            <WidgetSuspenseAndErrorBoundary title="Metadata">
              <ControllerMetadataWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
            {controllerVersion.isCOS ? (
              <WidgetSuspenseAndErrorBoundary title="Service sets">
                <ServiceSetsWidgetCOS controllerName={controllerName} />
              </WidgetSuspenseAndErrorBoundary>
            ) : (
              <WidgetSuspenseAndErrorBoundary title="Wireless networks">
                <Passwords controllerName={controllerName} />
              </WidgetSuspenseAndErrorBoundary>
            )}
          </PageFirstColumn>
          <PageSecondColumn>
            <WidgetSuspenseAndErrorBoundary title="Access points">
              <AccessPointsWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
            <WidgetSuspenseAndErrorBoundary title="Clients">
              <ClientsWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
          </PageSecondColumn>
        </PageColumns>
      </PageSection>
    </Page>
  );
}
