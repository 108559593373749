import { useMutation } from 'react-query';

import { addNOCUserToCompany } from '../api/users_api';

export const useOpenDashboardMutation = (companyName?: string) =>
  useMutation(
    ['open_dashboard', companyName],
    async () => {
      if (!companyName) {
        throw new Error('"companyName" can not be null or undefined');
      }

      await addNOCUserToCompany(companyName);
    },
    {
      onSuccess: () => {
        window.location.href = `${import.meta.env.DASHBOARD_URL}/org/${companyName}`;
      },
    },
  );
