/* eslint-disable react/no-unstable-nested-components */
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import {
  isDefinedAndNotEmpty,
  LifecycleStatusBadge,
  OnlineOfflineStatusBadge,
} from '@meterup/common';
import { Button, Icon, Text } from '@meterup/metric';
import { api } from '@meterup/proto';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import type { ControllerJSONWithStats } from '../../api/types';
import type { TabFilter } from '../../components/AutoTable/TabFilters';
import { fetchControllersWithStats } from '../../api/controllers_api';
import { AutoTable } from '../../components/AutoTable/AutoTable';
import { TabFilters } from '../../components/AutoTable/TabFilters';
import { Nav } from '../../components/Nav';
import { Page, PageHeader, PageTitle } from '../../components/Page';
import { paths } from '../../constants';
import { styled } from '../../stitches';
import { getControllerVersion } from '../../utils/controllers';
import { makeLink } from '../../utils/makeLink';

const Box = styled('div', {});

enum FilterStrategy {
  All = 'All',
  Online = 'Online',
  Offline = 'Offline',
  HasClients = 'HasClients',
  HasDevices = 'HasDevices',
  HasCustomer = 'HasCustomer',
  HasNoCustomer = 'HasNoCustomer',
  Installed = 'Installed',
  NotInstalled = 'NotInstalled',
}

const isAll = () => true;
const isOnline = (d: ControllerJSONWithStats) => d.controller.status === 'online';
const isOffline = (d: ControllerJSONWithStats) => d.controller.status === 'offline';
const hasClients = (d: ControllerJSONWithStats) => (d.stat?.connected_clients ?? 0) > 0;
const hasDevices = (d: ControllerJSONWithStats) => (d.stat?.connected_devices ?? 0) > 0;
const hasCustomer = (d: ControllerJSONWithStats) => isDefinedAndNotEmpty(d.controller.company_slug);
const hasNoCustomer = (d: ControllerJSONWithStats) =>
  !isDefinedAndNotEmpty(d.controller.company_slug);
const isInstalled = (d: ControllerJSONWithStats) =>
  d.controller.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY ||
  d.controller.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP;
const isNotInstalled = (d: ControllerJSONWithStats) =>
  d.controller.lifecycle_status !== api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY &&
  d.controller.lifecycle_status !== api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP;

const getPredicateForFilterStrategy = (filterStrategy: FilterStrategy) =>
  match(filterStrategy)
    .with(FilterStrategy.All, () => isAll)
    .with(FilterStrategy.Online, () => isOnline)
    .with(FilterStrategy.Offline, () => isOffline)
    .with(FilterStrategy.HasClients, () => hasClients)
    .with(FilterStrategy.HasDevices, () => hasDevices)
    .with(FilterStrategy.HasCustomer, () => hasCustomer)
    .with(FilterStrategy.HasNoCustomer, () => hasNoCustomer)
    .with(FilterStrategy.Installed, () => isInstalled)
    .with(FilterStrategy.NotInstalled, () => isNotInstalled)
    .exhaustive();

export const Meta: PagefileMetaFn = () => ({
  path: '/',
});

const columns: Column<ControllerJSONWithStats>[] = [
  {
    Header: 'Customer',
    accessor: (row) => row.controller.company_slug,
    Cell: (props: CellProps<ControllerJSONWithStats, string>) => {
      const companySlug = props.row.original.controller.company_slug;

      return isDefinedAndNotEmpty(companySlug) ? (
        <Button
          as={Link}
          to={Nav.makeTo({
            root: makeLink(paths.pages.CompaniesList, {}),
            drawer: makeLink(paths.drawers.CompanySummary, {
              companyName: companySlug,
            }),
          })}
          size="small"
          variant="tertiary"
        >
          {props.value}
        </Button>
      ) : null;
    },
  },
  {
    Header: 'Controller name',
    accessor: (row) => row.controller.name,
    Cell: (props: CellProps<ControllerJSONWithStats, string>) => (
      <Button
        as={Link}
        to={makeLink(paths.pages.ControllerDetails, {
          controllerName: props.row.original.controller.name,
        })}
        size="small"
        variant="tertiary"
      >
        {props.value}
      </Button>
    ),
  },
  {
    Header: 'Generation',
    accessor: (row) => getControllerVersion(row.controller).versionString,
  },
  {
    Header: 'Address',
    accessor: (row) => row.controller.address,
  },
  {
    Header: 'Devices',
    accessor: (row) => row.stat?.connected_devices,
    Cell: (props: CellProps<ControllerJSONWithStats, number>) => (
      <Button
        as={Link}
        to={makeLink(paths.pages.ControllerDevicesList, {
          controllerName: props.row.original.controller.name,
        })}
        size="small"
        variant="tertiary"
      >
        {props.value ?? 0}
      </Button>
    ),
  },
  {
    Header: 'All clients',
    accessor: (row) => row.stat?.connected_clients,
    Cell: (props: CellProps<ControllerJSONWithStats, number>) => (
      <Button
        as={Link}
        to={makeLink(paths.pages.ControllerClientsList, {
          controllerName: props.row.original.controller.name,
        })}
        size="small"
        variant="tertiary"
      >
        {props.value ?? 0}
      </Button>
    ),
  },
  {
    Header: 'Lifecycle status',
    accessor: (row) => row.controller.lifecycle_status,
    Cell: LifecycleStatusBadge,
  },
  {
    Header: 'Online status',
    accessor: (row) => row.controller.status,
    Cell: OnlineOfflineStatusBadge,
  },
];

const empty: ControllerJSONWithStats[] = [];

export default function ControllersList() {
  const [filterStrategy, setFilterStrategy] = useState<FilterStrategy>(FilterStrategy.Installed);

  const controllers =
    useQuery(['controllers', 'with-stats'], () => fetchControllersWithStats(), {
      suspense: true,
    }).data ?? empty;

  const filteredControllers = controllers.filter(getPredicateForFilterStrategy(filterStrategy));

  const filters: TabFilter<FilterStrategy>[] = [
    {
      key: FilterStrategy.All,
      label: 'All',
      count: controllers.length,
    },
    {
      key: FilterStrategy.Installed,
      label: 'Installed',
      count: controllers.filter(isInstalled).length,
    },
    {
      key: FilterStrategy.HasCustomer,
      label: 'Has customer',
      count: controllers.filter(hasCustomer).length,
    },
    {
      key: FilterStrategy.Online,
      label: 'Online',
      count: controllers.filter(isOnline).length,
    },
    {
      key: FilterStrategy.Offline,
      label: 'Offline',
      count: controllers.filter(isOffline).length,
    },
    {
      key: FilterStrategy.HasClients,
      label: 'Has clients',
      count: controllers.filter(hasClients).length,
    },
    {
      key: FilterStrategy.HasDevices,
      label: 'Has devices',
      count: controllers.filter(hasDevices).length,
    },
    {
      key: FilterStrategy.HasNoCustomer,
      label: 'Has no customer',
      count: controllers.filter(hasNoCustomer).length,
    },
    {
      key: FilterStrategy.NotInstalled,
      label: 'Not installed',
      count: controllers.filter(isNotInstalled).length,
    },
  ];

  const navigate = useNavigate();

  const { state } = useCommand();

  useRegisterCommands(
    [
      state.nodeFactory.directory({
        id: 'filters',
        display: 'Filter by…',
        label: 'Filter by…',
        icon: 'filter',
        children: filters.map((filter) => {
          const display = filter.count
            ? `${filter.label} (${filter.count})`
            : (filter.label as string);

          return state.nodeFactory.action({
            id: filter.key,
            display,
            label: display,
            onSelect() {
              setFilterStrategy(filter.key);
              state.ui.update((ui) => {
                // eslint-disable-next-line no-param-reassign
                ui.search = '';
              });
              if (state.currentRoot.parent) {
                state.updateCurrentRoot(state.currentRoot.parent);
              }
              return true;
            },
          });
        }),
      }),
    ],
    [],
  );

  useRegisterCommands(
    filteredControllers.map((filtered) =>
      state.nodeFactory.action({
        id: filtered.controller.sid,
        priority: Priority.High,
        group: `Filtered controllers (${filterStrategy})`,
        label: filtered.controller.name,
        icon: 'controller',
        display: (
          <Box
            css={{
              display: 'grid',
              gridAutoFlow: 'column',
              alignItems: 'center',
              gap: '$10',
              truncate: true,
            }}
          >
            {filtered.controller.company_slug && (
              <>
                <Text size={14}>{filtered.controller.company_slug}</Text>
                <Icon icon="arrowRight" size={8} />
              </>
            )}
            <Text size={14}>{filtered.controller.name}</Text>
            <Text size={14}>({filtered.controller.address})</Text>
          </Box>
        ),
        synonyms: `${filtered.controller.address} ${filtered.controller.company_slug}`,
        onSelect() {
          navigate(
            makeLink(paths.pages.ControllerDetails, {
              controllerName: filtered.controller.name,
            }),
          );
        },
      }),
    ),
    [filteredControllers],
  );

  return (
    <Page>
      <PageHeader>
        <PageTitle>Controllers</PageTitle>
      </PageHeader>
      <AutoTable
        columns={columns}
        data={filteredControllers}
        tabs={
          <TabFilters
            filters={filters}
            activeFilterKey={filterStrategy}
            onActivateFilter={(f) => setFilterStrategy(f.key)}
          />
        }
      />
    </Page>
  );
}
