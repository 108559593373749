import {
  getFirstUsableAddress,
  getLastUsableAddress,
  isDefined,
  safeParseAddress4,
} from '@meterup/common';
import { ListItemLabel, ListItemPair, ListItemValue } from '@meterup/metric';
import React from 'react';

export const FirstAndLastAddresses = ({ ipRange }: { ipRange?: string }) => {
  const addr = isDefined(ipRange) ? safeParseAddress4(ipRange.trim()) : null;

  return (
    <>
      <ListItemPair>
        <ListItemLabel>First usable address</ListItemLabel>
        <ListItemValue>
          {isDefined(addr) ? getFirstUsableAddress(addr).correctForm() : <>...</>}
        </ListItemValue>
      </ListItemPair>
      <ListItemPair>
        <ListItemLabel>Last usable address</ListItemLabel>
        <ListItemValue>
          {isDefined(addr) ? getLastUsableAddress(addr).correctForm() : <>...</>}
        </ListItemValue>
      </ListItemPair>
    </>
  );
};
