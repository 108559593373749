import { observer, Priority, useCommand, useRegisterCommands } from '@meterup/command';
import {
  DesktopSidebar,
  MobileSidebar,
  MobileSidebarToggle,
  MobileSidebarToggleContainer,
  SidebarGroup,
  SidebarNavItem,
} from '@meterup/metric';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { paths } from '../../constants';
import { useIsPathActiveFn } from '../../hooks/useIsPathActiveFn';
import { makeLink } from '../../utils/makeLink';
import { Nav } from '../Nav';
import { MobileSidebarModal } from './MobileSidebarModal';

const SidebarEntries = observer(({ onClickEntry }: { onClickEntry?: () => void }) => {
  const isPathActive = useIsPathActiveFn();
  const location = Nav.useRegionLocation('root');

  const { state } = useCommand();
  const navigate = useNavigate();

  const isControllersLinkActive =
    location.pathname === '/' || isPathActive(paths.pages.ControllerDetails);
  const isCompaniesLinkActive = isPathActive(paths.pages.CompaniesList);
  const isGlobalStatsLinkActive = isPathActive(paths.pages.GlobalStats);

  useRegisterCommands(
    [
      state.nodeFactory.action({
        id: 'controllers',
        group: 'Navigation',
        label: 'Controllers',
        display: 'Controllers',
        synonyms: 'go to home',
        icon: 'controller',
        shortcut: 'g h',
        onSelect: () => navigate(makeLink(paths.pages.ControllersList, {})),
        priority: Priority.Low,
      }),
      state.nodeFactory.action({
        id: 'companies',
        group: 'Navigation',
        label: 'Companies',
        display: 'Companies',
        synonyms: 'go to',
        icon: 'company',
        shortcut: 'g c',
        onSelect: () => navigate(makeLink(paths.pages.CompaniesList, {})),
        priority: Priority.Low,
      }),
      state.nodeFactory.action({
        id: 'navigation',
        group: 'Navigation',
        label: 'Statistics',
        display: 'Statistics',
        synonyms: 'go to',
        icon: 'reporting',
        shortcut: 'g s',
        onSelect: () => navigate(makeLink(paths.pages.GlobalStats, {})),
        priority: Priority.Low,
      }),
    ],
    [location.pathname],
  );

  return (
    <SidebarGroup>
      <SidebarNavItem
        as={Link}
        to={makeLink(paths.pages.ControllersList, {})}
        label="Controllers"
        isSelected={isControllersLinkActive}
        icon="controller"
        onClick={onClickEntry}
      />
      <SidebarNavItem
        as={Link}
        to={makeLink(paths.pages.CompaniesList, {})}
        label="Companies"
        isSelected={isCompaniesLinkActive}
        icon="company"
        onClick={onClickEntry}
      />
      <SidebarNavItem
        as={Link}
        to={makeLink(paths.pages.GlobalStats, {})}
        label="Statistics"
        isSelected={isGlobalStatsLinkActive}
        icon="reporting"
        onClick={onClickEntry}
      />
    </SidebarGroup>
  );
});

export const NOCDesktopSidebar = () => (
  <DesktopSidebar>
    <SidebarEntries />
  </DesktopSidebar>
);

export const NOCMobileSidebar = ({ onClose }: { onClose: () => void }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <MobileSidebarToggle icon="cross" label="Navigation" onClick={onClose} />
      </MobileSidebarToggleContainer>
      <SidebarEntries onClickEntry={onClose} />
    </MobileSidebar>
  </MobileSidebarModal>
);
