import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, getErrorMessage, isDefined } from '@meterup/common';
import {
  Alert,
  Button,
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { styled } from '@stitches/react';
import { Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidVPNClientData } from './form_data';
import { createVPNClient } from '../../../../api/vpn';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { makeCloseDrawerLink, makeDrawerLink } from '../../../../utils/makeLink';
import { toVPNClientCreateRequest, validVPNClientData } from './form_data';
import { VPNClientCreateForm } from './VPNClientCreateForm';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/vpn-clients/new',
});

const StyledForm = styled('form', {
  display: 'contents',
});

export default function VPNClientCreate() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VPNClientCreate),
  );

  const closeDrawer = useCloseDrawerCallback();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createDrawerMutation = useMutation(
    async (data: ValidVPNClientData) => {
      const apiData = toVPNClientCreateRequest(data);
      const vpnClient = await createVPNClient(controllerName, apiData.vpnClientData);
      return vpnClient;
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['controllers', controllerName, 'vpn-clients']);
        navigate(
          makeDrawerLink(paths.drawers.VPNClientDetailPage, {
            controllerName,
            clientSid: result.client!.sid,
          }),
        );
      },
    },
  );

  return (
    <Formik<ValidVPNClientData>
      initialValues={{
        public_key: '',
        name: '',
        email: '',
      }}
      validationSchema={toFormikValidationSchema(validVPNClientData)}
      onSubmit={(values) => createDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Create VPN client</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton onClick={closeDrawer} />
              </DrawerControls>
            </DrawerHeader>

            <DrawerContent>
              {isDefined(createDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={getErrorMessage(createDrawerMutation.error)}
                />
              )}
              <VPNClientCreateForm />
            </DrawerContent>

            <DrawerFooter>
              <DrawerControls>
                <Button
                  type="button"
                  variant="secondary"
                  as={Link}
                  to={makeCloseDrawerLink()}
                  loading={createDrawerMutation.isLoading}
                >
                  Cancel
                </Button>
                <Button type="submit" loading={createDrawerMutation.isLoading}>
                  Save
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
