import {
  FieldContainer,
  ListItemHeader,
  ListTitle,
  PrimaryField,
  Textarea,
  TextInput,
} from '@meterup/metric';

import { FieldProvider } from '../../../../components/FieldProvider';

export const VPNClientCreateForm = () => (
  <FieldContainer>
    <ListItemHeader>
      <ListTitle>VPN client details</ListTitle>
    </ListItemHeader>
    <FieldProvider name="name">
      <PrimaryField label="Name" element={<TextInput />} />
    </FieldProvider>
    <FieldProvider name="email">
      <PrimaryField label="Email" element={<TextInput />} />
    </FieldProvider>
    <FieldProvider name="public_key">
      <PrimaryField label="Public Key" element={<Textarea />} />
    </FieldProvider>
  </FieldContainer>
);
