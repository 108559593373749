import type { api } from '@meterup/proto';
import {
  bytesPerSecond,
  expectDefinedOrThrow,
  formatDataRateBytes,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
  Tooltip,
  UserRoleBadge,
} from '@meterup/common';
import {
  BodyMono2,
  CopyCapsule,
  HStack,
  MinList,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  SmallMono2,
  Subheading2,
  VStack,
} from '@meterup/metric';
import { get } from 'lodash';
import { useQuery } from 'react-query';

import { fetchControllerJSON, fetchControllerState } from '../../../../api/controllers_api';
import { fetchCompanyUserJSON } from '../../../../api/users_api';
import { CopyToClipboardButtonMinimal } from '../../../../components/CopyToClipboardButton';
import { TimestampWithTimezone } from '../../../../components/timestamps';
import { useConfigEditor } from '../../../../context/ConfigEditorContext';
import { useCurrentTimezone } from '../../../../providers/CurrentTimezoneProvider';

export default function VPNClientDetailContent({
  controllerName,
  vpnClient,
  vpnServer,
}: {
  controllerName: string;
  vpnClient: api.VPNClient;
  vpnServer: api.VPNServer;
}) {
  const controller = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;
  const timezone = useCurrentTimezone();

  expectDefinedOrThrow(controller, new ResourceNotFoundError('Controller response not found'));

  const user = useQuery(
    ['company', controller.company_slug, 'users', vpnClient.user_sid],
    () => fetchCompanyUserJSON(controller.company_slug, vpnClient.user_sid),
    { suspense: true },
  ).data;

  const stateData = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  const timestampToISO = function (unixTimestamp: number) {
    const date = new Date(unixTimestamp * 1000);
    return date.toISOString();
  };

  const peers = get(stateData?.state, ['meter.v1.wg-vpn-client-manager.peers']);
  const peersObj = get(peers, ['peers']);
  const logs = get(peersObj, [vpnClient.public_key.toString()]);
  const privateVLAN = useConfigEditor().draftModel.getVLANByName('private');

  expectDefinedOrThrow(user, new ResourceNotFoundError('User response not found'));
  expectDefinedOrThrow(stateData, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(stateData.state, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(
    privateVLAN,
    new ResourceNotFoundError(
      'Private VLAN config details not found, unable to format WireGuard config',
    ),
  );

  const dnsServers = privateVLAN.json.dhcp?.['dns-servers'] ?? [];

  const wgConfig = `
[Interface]
PrivateKey = $YOUR_PRIVATE_KEY
Address = ${vpnClient.ip_address}/32
DNS = ${dnsServers.join(', ')}

[Peer]
PublicKey = ${vpnServer.public_key}
AllowedIPs = 10.0.0.0/8, 224.0.0.0/4
Endpoint = ${vpnServer.endpoint}:${vpnServer.port}
PersistentKeepalive = 30`.trim();

  return (
    <>
      <VStack align="center" spacing={10 as any}>
        <Subheading2>
          <CopyCapsule
            textValue={vpnClient.name}
            aria-label="Copy VPN client name"
            arrangement="leading-icon"
          >
            {vpnClient.name}
          </CopyCapsule>
        </Subheading2>
      </VStack>
      <MinList>
        <MinListItemHeader icon="user">
          <MinListTitle>User</MinListTitle>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Name</MinListItemLabel>
          <MinListItemValue>
            {user.first_name ?? '-'} {user.last_name}
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Email</MinListItemLabel>
          <MinListItemValue>
            <BodyMono2>
              <CopyCapsule
                textValue={user.email}
                aria-label="Copy user email"
                arrangement="leading-icon"
              >
                {user.email}
              </CopyCapsule>
            </BodyMono2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Role</MinListItemLabel>
          <MinListItemValue>
            <UserRoleBadge value={user.company_role} />
          </MinListItemValue>
        </MinListItemPair>
      </MinList>
      <MinList>
        <MinListItemHeader icon="client">
          <MinListTitle>Details</MinListTitle>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Name</MinListItemLabel>
          <MinListItemValue>{vpnClient.name}</MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>IP address</MinListItemLabel>
          <MinListItemValue>
            <BodyMono2 style={{ wordBreak: 'break-all' }}>
              <CopyCapsule
                textValue={vpnClient.ip_address?.toString() ?? ''}
                aria-label="Copy VPN client IP address"
                arrangement="leading-icon"
              >
                {vpnClient.ip_address}
              </CopyCapsule>
            </BodyMono2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Public key</MinListItemLabel>
          <MinListItemValue>
            <BodyMono2 style={{ wordBreak: 'break-all', textAlign: 'right' }}>
              <CopyCapsule
                textValue={vpnClient.public_key?.toString() ?? ''}
                aria-label="Copy VPN client public key"
                arrangement="leading-icon"
              >
                {vpnClient.public_key}
              </CopyCapsule>
            </BodyMono2>
          </MinListItemValue>
        </MinListItemPair>
        {isDefinedAndNotEmpty(vpnClient.created_at) && (
          <MinListItemPair>
            <MinListItemLabel>Created at</MinListItemLabel>
            <MinListItemValue>
              <TimestampWithTimezone value={vpnClient.created_at} timezone={timezone} />
            </MinListItemValue>
          </MinListItemPair>
        )}
        {isDefinedAndNotEmpty(vpnClient.created_by) && (
          <MinListItemPair>
            <MinListItemLabel>Created by</MinListItemLabel>
            <MinListItemValue>
              <BodyMono2 style={{ wordBreak: 'break-all' }}>{vpnClient.created_by}</BodyMono2>
            </MinListItemValue>
          </MinListItemPair>
        )}
      </MinList>
      <MinList>
        <MinListItemHeader icon="wrench">
          <HStack justify="between" align="center" width="full">
            <MinListTitle>WireGuard Config</MinListTitle>
            <CopyToClipboardButtonMinimal text={wgConfig} />
          </HStack>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemValue>
            <SmallMono2
              style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', display: 'block' }}
              as="pre"
            >
              {wgConfig}
            </SmallMono2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>
      {isDefinedAndNotEmpty(logs) && (
        <MinList>
          <MinListItemHeader icon="log">
            <MinListTitle>Logs</MinListTitle>
          </MinListItemHeader>
          <MinListItemPair>
            <MinListItemLabel>Rx bytes</MinListItemLabel>
            <MinListItemValue>
              <BodyMono2 style={{ wordBreak: 'break-all' }}>
                {formatDataRateBytes(logs.rx_bytes, bytesPerSecond)}
              </BodyMono2>
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>Tx bytes</MinListItemLabel>
            <MinListItemValue>
              <MinListItemValue>
                <BodyMono2 style={{ wordBreak: 'break-all' }}>
                  {formatDataRateBytes(logs.tx_bytes, bytesPerSecond)}
                </BodyMono2>
              </MinListItemValue>
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>Last handshake time</MinListItemLabel>
            <MinListItemValue>
              {get(logs, ['last_handshake_time']) !== 0 && (
                <TimestampWithTimezone
                  value={timestampToISO(logs.last_handshake_time)}
                  timezone={timezone}
                />
              )}
              {get(logs, ['last_handshake_time']) === 0 && (
                <Tooltip content="This peer has not completed a handshake since the VPN service was last restarted.">
                  Not since last restart
                </Tooltip>
              )}
            </MinListItemValue>
          </MinListItemPair>
          <MinListItemPair>
            <MinListItemLabel>Observed at</MinListItemLabel>
            <MinListItemValue>
              <TimestampWithTimezone value={get(peers, ['observed_at'])} timezone={timezone} />
            </MinListItemValue>
          </MinListItemPair>
        </MinList>
      )}
    </>
  );
}
