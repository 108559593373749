import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import {
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
  isGuest,
  NeutralBadge,
  ResourceNotFoundError,
  SignalStrengthBadge,
  WiredWirelessBadge,
} from '@meterup/common';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import type { TabFilter } from '../AutoTable/TabFilters';
import { fetchClientsJSON } from '../../api/clients_api';
import { fetchControllerJSON } from '../../api/controllers_api';
import { paths } from '../../constants';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
  FilterStrategy,
  getPredicateFromStrategy,
  isMeterAccessPoint,
  isMeterHardware,
  isMeterSwitch,
  isWired,
  isWireless,
} from '../../utils/clients';
import { makeDrawerLink } from '../../utils/makeLink';
import { AutoTable } from '../AutoTable/AutoTable';
import { TabFilters } from '../AutoTable/TabFilters';
import { Nav } from '../Nav';
import { Page } from '../Page';
import { DistanceToNowTimestampOrNull } from '../timestamps';

const Clients = ({
  controllerName,
  deviceName,
}: {
  controllerName: string;
  deviceName?: string;
}) => {
  const [filterStrategy, setFilterStrategy] = useState<FilterStrategy>(FilterStrategy.All);

  const controller = useQuery(
    ['controller', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(
    controller,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const allClients =
    useQuery(['clients', controllerName, deviceName], () =>
      fetchClientsJSON(controllerName, deviceName),
    ).data ?? [];

  const exportFileName = isDefinedAndNotEmpty(deviceName)
    ? `${controllerName}_${deviceName}_clients_export.csv`
    : `${controllerName}_clients_export.csv`;

  useDocumentTitle('Corporate Clients', controllerName);

  const columns = React.useMemo(
    (): Column<api.ControllerClient>[] => [
      {
        Header: 'Name',
        accessor: (d) => d.lease?.name,
      },
      {
        Header: 'IP',
        accessor: (d) => d.lease?.ip,
      },
      {
        Header: 'MAC',
        accessor: (d) => d.lease?.mac,
      },
      {
        Header: 'Connection',
        accessor: (d) => (isDefinedAndNotEmpty(d.apname) ? 'wireless' : 'wired'),
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (row: CellProps<any, 'wireless' | 'wired'>) => (
          <WiredWirelessBadge value={row.value} />
        ),
      },
      {
        Header: 'Access point',
        accessor: (d) => d.access_point_location ?? d.apname,
      },
      {
        Header: 'Channel',
        accessor: (d) => (isWireless(d) ? d.channel : null),
      },
      {
        Header: 'Signal',
        accessor: (d) => (isWireless(d) ? d.signal : null),
        Cell: SignalStrengthBadge,
      },
      {
        Header: 'VLAN',
        accessor: (d) => (isWireless(d) ? d.vlan : null),
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (row: CellProps<any>) => row.value && <NeutralBadge>{row.value}</NeutralBadge>,
      },
      {
        Header: 'Last seen',
        accessor: (d) => d.last_seen,
        Cell: DistanceToNowTimestampOrNull,
      },
    ],
    [],
  );

  const filters: TabFilter<FilterStrategy>[] = [
    {
      key: FilterStrategy.All,
      label: 'All',
      count: allClients?.length,
    },
    {
      key: FilterStrategy.Wired,
      label: 'Wired',
      count: allClients?.filter(isWired).length,
    },
    {
      key: FilterStrategy.Wireless,
      label: 'Wireless',
      count: allClients?.filter(isWireless).length,
    },
    {
      key: FilterStrategy.Guest,
      label: 'Guest',
      count: allClients?.filter(isGuest).length,
    },
    {
      key: FilterStrategy.Meter,
      label: 'Hardware',
      count: allClients?.filter(isMeterHardware).length,
    },
    {
      key: FilterStrategy.MeterSwitch,
      label: 'Switch',
      count: allClients?.filter(isMeterSwitch).length,
    },
    {
      key: FilterStrategy.MeterAccessPoint,
      label: 'Access point',
      count: allClients?.filter(isMeterAccessPoint).length,
    },
  ];

  const filter = getPredicateFromStrategy(filterStrategy);
  const filteredClients = allClients?.filter(filter);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ClientDetail);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Page>
      <AutoTable
        columns={columns}
        data={filteredClients}
        tabs={
          <TabFilters
            filters={filters}
            activeFilterKey={filterStrategy}
            onActivateFilter={(f) => setFilterStrategy(f.key)}
          />
        }
        csvFileName={exportFileName}
        onRowDeselect={closeDrawer}
        isRowSelected={(row) => isDefined(drawerParams) && row.lease?.mac === drawerParams.mac}
        linkProps={(row) => ({
          to: makeDrawerLink(paths.drawers.ClientDetail, {
            controllerName,
            // TODO: Determine if lease is ever undefined?
            mac: row.lease?.mac ?? '',
          }),
        })}
      />
    </Page>
  );
};

export default Clients;
