import type { api } from '@meterup/proto';
import type { Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { Button } from '@meterup/metric';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import type { TabFilter } from '../../../components/AutoTable/TabFilters';
import { fetchCompaniesJSON } from '../../../api/company_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { TabFilters } from '../../../components/AutoTable/TabFilters';
import { Nav } from '../../../components/Nav';
import { Page, PageHeader, PageTitle } from '../../../components/Page';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useOpenDashboardMutation } from '../../../hooks/useOpenDashboardMutation';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';

enum FilterStrategy {
  All = 'All',
  HasController = 'HasController',
}

const columns: Column<api.CompanyResponse>[] = [
  {
    Header: 'Company name',
    accessor: (row) => row.name,
  },
  {
    Header: 'Slug',
    accessor: (row) => row.slug,
  },
];

export const Meta: PagefileMetaFn = () => ({
  path: '/companies',
});

const empty: api.CompanyResponse[] = [];

export default function CompaniesList() {
  const [filterStrategy, setFilterStrategy] = useState<FilterStrategy>(
    FilterStrategy.HasController,
  );
  const closeDrawer = useCloseDrawerCallback();

  const companies =
    useQuery(['companies', 'filter', filterStrategy], () =>
      fetchCompaniesJSON(
        match(filterStrategy)
          .with(FilterStrategy.HasController, () => true)
          .with(FilterStrategy.All, () => false)
          .exhaustive(),
      ),
    ).data ?? empty;

  const filters: TabFilter<FilterStrategy>[] = [
    {
      key: FilterStrategy.All,
      label: 'All',
    },
    {
      key: FilterStrategy.HasController,
      label: 'Has controller',
    },
  ];

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.CompanySummary);

  const navigate = useNavigate();

  const [hoveredCompany, setHoveredCompany] = useState<api.CompanyResponse | null>(null);

  const inContextCompany = useMemo(
    () =>
      drawerParams?.companyName
        ? companies.find((c) => c.slug === drawerParams.companyName) ?? null
        : hoveredCompany,
    [companies, drawerParams?.companyName, hoveredCompany],
  );

  const { state } = useCommand();

  const openDashboardMutation = useOpenDashboardMutation(inContextCompany?.name);

  useRegisterCommands(
    inContextCompany
      ? [
          state.nodeFactory.action({
            id: 'open-dashboard',
            label: 'Open dashboard',
            display: 'Open dashboard',
            group: `${inContextCompany.name} - (${inContextCompany.slug})`,
            onSelect() {
              openDashboardMutation.mutate();
            },
            priority: Priority.High,
            shortcut: '$mod+o',
          }),
          state.nodeFactory.action({
            id: 'view-users',
            label: 'View users',
            display: 'View users',
            group: `${inContextCompany.name} - (${inContextCompany.slug})`,
            priority: Priority.High,
            shortcut: '$mod+Shift+u',
            onSelect() {
              navigate(
                makeLink(paths.pages.CompanyUsers, {
                  companyName: inContextCompany.name,
                }),
              );
            },
          }),
        ]
      : [],
    [inContextCompany],
  );

  useRegisterCommands(
    [
      state.nodeFactory.directory({
        id: 'company-filter-by',
        display: 'Filter by…',
        label: 'Filter by…',
        icon: 'filter',
        children: filters.map((filter) =>
          state.nodeFactory.action({
            id: filter.key,
            display: filter.label,
            label: filter.label as string,
            onSelect() {
              setFilterStrategy(filter.key);
            },
          }),
        ),
      }),
    ],
    [],
  );

  return (
    <Page>
      <PageHeader>
        <PageTitle>Companies</PageTitle>
      </PageHeader>
      <AutoTable
        columns={columns}
        data={companies}
        onRowDeselect={closeDrawer}
        isRowSelected={(row) => row.slug === drawerParams?.companyName}
        linkProps={(row) => ({
          to: makeDrawerLink(paths.drawers.CompanySummary, { companyName: row.slug }),
          onPointerEnter() {
            if (drawerParams) {
              return;
            }

            setHoveredCompany(row);
          },
        })}
        tabs={
          <TabFilters
            filters={filters}
            activeFilterKey={filterStrategy}
            onActivateFilter={(f) => setFilterStrategy(f.key)}
          />
        }
        additionalControls={
          <Button
            as={Link}
            to={makeDrawerLink(paths.drawers.CreateCompany, {})}
            arrangement="leading-icon"
            icon="plusCircle"
            variant="tertiary"
          >
            Create
          </Button>
        }
      />
    </Page>
  );
}
