import type { api } from '@meterup/proto';
import { getMany, getOne, mutateVoid } from '@meterup/common';
import { sortBy } from 'lodash';

import { axiosInstanceJSON } from './api_clients';

export const updateAP = async (
  controllerName: string,
  apName: string,
  location: string,
  status: api.AccessPointStatus,
) =>
  mutateVoid(async () => {
    const data: Partial<api.AccessPointPutRequest> = {
      skip_greengrass_registration: true,
      status,
      location,
    };
    await axiosInstanceJSON.put(`/v1/controllers/${controllerName}/access-points/${apName}`, data);
  });

export const postFloorPlan = async (controllerName: string, file: File) =>
  mutateVoid(async () => {
    const data = new FormData();
    data.append('file', file);

    await axiosInstanceJSON.post(
      `/v1/controllers/${controllerName}/floor-plan`,

      data,
    );
  });

export const fetchFloorPlan = async (controllerName: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get(`/v1/controllers/${controllerName}/floor-plan`, {
      responseType: 'blob',
    });

    return result.data ? URL.createObjectURL(result.data) : null;
  });

export const fetchDevices = async (controllerName: string) =>
  getOne(async () => {
    const results = await axiosInstanceJSON.get<api.NOCControllerDevicesResponse>(
      `/v1/noc/controllers/${controllerName}/devices`,
    );
    return results.data;
  });

export const fetchAccessPointsJSON = async (controllerName: string) =>
  getMany(async () => {
    const results = await axiosInstanceJSON.get<api.NOCControllerDevicesResponse>(
      `/v1/noc/controllers/${controllerName}/devices`,
    );

    return sortBy(Object.values(results.data.access_points), (ap) => [
      ap.access_point?.status,
      ap.access_point?.location,
    ]);
  });

export const fetchAccessPointJSON = async (
  controllerName: string,
  deviceName: string,
): Promise<api.AccessPointRadiosResponse | null> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.NOCControllerDevicesResponse>(
      `/v1/noc/controllers/${controllerName}/devices`,
    );
    return result.data.access_points[deviceName] ?? null;
  });

export const deleteAccessPoint = async (controllerName: string, apName: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/controllers/${controllerName}/access-points/${apName}`);
  });

export const uploadAccessPointsCSV = async (controllerName: any, file: File) =>
  mutateVoid(async () => {
    const data = await file.text();
    await axiosInstanceJSON.post(`/v1/controllers/${controllerName}/access-points`, data, {
      headers: { 'Content-Type': 'text/csv' },
    });
  });
