import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { Alert, Button } from '@meterup/metric';
import { get } from 'lodash';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { fetchControllerJSON, fetchControllerState } from '../../../../api/controllers_api';
import { createVPNServer, fetchVPNServer } from '../../../../api/vpn';
import { Nav } from '../../../../components/Nav';
import { Page, PageSection } from '../../../../components/Page';
import VPNClientsWidget from '../../../../components/VPN/VPNClientsWidget';
import { paths } from '../../../../constants';
import { useControllerVersion } from '../../../../hooks/useControllerVersion';
import { VPNServerCreateDialog } from '../../../drawers/controllers/vpn/VPNServerCreate';

export const Meta = () => ({
  path: '/controllers/:controllerName/vpn',
});

export default function VPNListPage() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  const controller = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  const vpnServer = useQuery(
    ['controller', controllerName, 'vpn-servers'],
    () => fetchVPNServer(controllerName),
    { suspense: true, enabled: true },
  ).data;

  const stateData = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  const controllerVersion = useControllerVersion(controllerName);

  expectDefinedOrThrow(controller, new ResourceNotFoundError('Controller response not found'));
  expectDefinedOrThrow(stateData, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(stateData.state, new ResourceNotFoundError('State response not found'));

  const [isOpen, setIsOpen] = useState(false);
  const publicKey = get(stateData?.state, ['meter.v1.wg-vpn-client-manager', 'public_key']);
  const queryClient = useQueryClient();

  const createVPNServerMutation = useMutation(
    async () => {
      if (!isDefinedAndNotEmpty(publicKey)) {
        setIsOpen(true);
      } else {
        await createVPNServer(controllerName, publicKey);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
      },
    },
  );

  return (
    <Page>
      {controllerVersion.isLegacy && (
        <Alert
          icon="attention"
          variant="negative"
          heading="Legacy controller"
          copy="This looks like a legacy controller. VPN management may be handled differently."
          cornerStyle="square"
        />
      )}
      {controllerVersion.isCOS && controller.company_slug === '' && (
        <Alert
          icon="attention"
          variant="negative"
          heading="No company associated"
          copy="This controller does not yet belong to a company. Please add a company to this controller before creating its VPN."
          cornerStyle="square"
        />
      )}
      {controllerVersion.isCOS && controller.company_slug !== '' && vpnServer === null && (
        <PageSection style={{ padding: 16 }}>
          <Alert
            heading="No VPN server"
            copy="This controller does not have a VPN server configured. You can create one below."
            trailingButtons={
              <Button
                onClick={() => createVPNServerMutation.mutate()}
                variant="secondary"
                arrangement="leading-icon"
              >
                Create server
              </Button>
            }
          />
          <VPNServerCreateDialog
            controllerName={controllerName}
            isOpen={isOpen}
            onOpenChange={setIsOpen}
          />
        </PageSection>
      )}
      {controllerVersion.isCOS && vpnServer != null && (
        <VPNClientsWidget controllerName={controllerName} />
      )}
    </Page>
  );
}
